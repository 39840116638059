<template>
  <v-form ref="order-create-form" @submit.prevent="formSubmit">
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn @click.prevent="$router.replace({ name: 'order' })" plain :disabled="isFetching">
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          <div>上一頁</div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <div class="page-title">{{ $route.meta.title }}</div>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  persistent-placeholder
                  hide-details="auto"
                  outlined
                  :disabled="isFetching"
                  :items="userList"
                  :filter="filterUser"
                  color="primary"
                  item-text="text"
                  item-value="value"
                  label="客戶"
                  v-model="userID"
                  :rules="[
                    v => {
                      return $validate.required(v) || '此位置不能留空'
                    },
                  ]"
                  @change="userChange"
                ></v-autocomplete>
              </v-col>
              <v-col md="6" cols="12">
                <form-control
                  inputType="select"
                  v-model="courseMode"
                  label="課程類型"
                  :disabled="isFetching || !$validate.DataValid(userID)"
                  :options="courseModeList"
                  :required="true"
                  @selectChange="getRelatedCourse"
                />
              </v-col>

              <v-col md="6" cols="12" v-if="courseMode === 'offline'">
                <form-control
                  inputType="datePicker"
                  v-model="courseDate"
                  label="課堂日期"
                  :required="true"
                  :disabled="isFetching"
                  :allowClearDate="true"
                  :allowedDates="filterAllowCourseDate"
                  @dateDialog="filterCourseByDate"
                />
              </v-col>

              <v-col md="6" cols="12" v-if="courseMode === 'online' || $validate.DataValid(courseDate)">
                <form-control
                  inputType="select"
                  v-model="courseID"
                  label="課程"
                  :disabled="isFetching"
                  :options="filterCourseList"
                  :required="true"
                  @selectChange="updateSelectedCourseData"
                />
              </v-col>

              <v-col md="6" cols="12" v-if="courseMode === 'offline' && $validate.DataValid(courseID)">
                <form-control
                  inputType="select"
                  v-model="amount"
                  label="人數"
                  :disabled="isFetching"
                  :options="applyCountList"
                  :required="true"
                  @selectChange="updatePersonAmount"
                />
              </v-col>
            </v-row>

            <v-row
              v-if="
                courseMode === 'offline' && $validate.DataValid(courseID) && $validate.DataValid(selectedCourseData)
              "
            >
              <v-col cols="12">
                <h3>{{ selectedCourseData.course_name }} ({{ selectedCourseData.course_code }})</h3>

                <div
                  class="timetable-grid"
                  v-if="$validate.DataValid(selectedCourseData.lessons) && selectedCourseData.lessons.length > 0"
                >
                  <template v-for="(lesson, index) in selectedCourseData.lessons">
                    <div :key="`date_${index}`">{{ lesson.date }} ({{ $Formatter.displayWeekday(lesson.date) }})</div>
                    <div :key="`time_${index}`">{{ lesson.start_time }} - {{ lesson.end_time }}</div>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="
        (courseMode === 'offline' && $validate.DataValid(amount)) ||
        (courseMode === 'online' && $validate.DataValid(courseID))
      "
    >
      <v-col md="8" cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <h3>付款方式</h3>
          </v-card-subtitle>
          <v-card-text class="my-3">
            <v-row v-if="courseMode === 'offline'">
              <v-col cols="12">
                <v-radio-group
                  :disabled="isFetching"
                  row
                  v-model="paymentType"
                  hide-details="auto"
                  class="mt-0 pt-0 mb-4"
                  @change="changePaymentType"
                >
                  <v-radio class="mr-6 my-sm-1" label="支付全部費用" value="all" :disabled="isFetching"></v-radio>
                  <v-radio
                    class="my-sm-1"
                    label="支付留位費用"
                    value="reserve"
                    :disabled="selectedCourseData.reservation_fee === 0 || isFetching"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex align-center">
                <form-control
                  class="mr-5"
                  inputType="select"
                  v-model="couponCode"
                  label="使用優惠劵"
                  :disabled="isFetching"
                  :options="couponList"
                  @selectChange="updateCouponCode"
                />
                <v-btn small depressed color="error" @click="cancelCouponCode" :disabled="isFetching">取消</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3 class="mb-2">上載轉帳紀錄</h3>
                <v-row>
                  <v-col md="4" sm="6" cols="12" class="code-area">
                    <v-img :max-width="200" :src="require(`@/assets/images/payment-fps-code.png`)"></v-img>
                  </v-col>
                  <v-col md="8" sm="6" cols="12">
                    <p style="margin-bottom: 8px !important; font-size: 15px; font-weight: 500">FPS Email</p>
                    <p style="margin-bottom: 0 !important; font-size: 15px">rex@innpression.com</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="8" cols="12">
                    <FormButtonUpload
                      :leftXl="6"
                      :leftLg="6"
                      :leftMd="6"
                      :rightXl="6"
                      :rightLg="6"
                      :rightMd="6"
                      :removeLeftPadding="true"
                      :removeRightPadding="true"
                      :uploadFile.sync="uploadRecord"
                      :showUploadFileName="true"
                    ></FormButtonUpload>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <h3>訂單明細</h3>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <div class="price-grid">
              <div class="grid-title">單價</div>
              <div class="grid-value">HK${{ selectedCourseData.price }}</div>
              <template v-if="courseMode === 'offline'">
                <div class="grid-title">留位費</div>
                <div class="grid-value">HK${{ selectedCourseData.reservation_fee }}</div>
                <div class="grid-title">人數</div>
                <div class="grid-value">{{ amount || 1 }}</div>
              </template>
              <div class="grid-title">訂單折扣</div>
              <div class="grid-value">-HK${{ discount }}</div>
              <div class="divider"></div>
              <div class="grid-title">訂單總額</div>
              <div class="grid-value emphasize">HK${{ total }}</div>
              <div class="grid-title">應付{{ paymentType === 'all' ? '金額' : '留位費' }}</div>
              <div class="grid-value emphasize">HK${{ payAmount }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary white--text" class="px-6" small depressed :disabled="isFetching" type="submit">
          提交
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'

export default {
  name: 'orderDetail',
  components: {
    FormControl,
    FormButtonUpload,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      userID: '',
      courseMode: '',
      courseDate: '',
      courseID: '',
      amount: '',
      couponCode: '',
      paymentType: 'all',
      uploadRecord: '',

      reservationFee: 0,
      discount: 0,
      total: 0,
      payAmount: 0,

      selectedCourseData: {},
      selectedCouponData: {},
      dbUserList: [],
      dbCourseList: [],
      dbCouponList: [],
      filterCourseList: [],

      submitSuccess: false,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    userList: function () {
      return this.dbUserList.map(obj => {
        return {
          text: `${obj.user_name} (${obj.email}) (${obj.phone || '-'})`,
          value: parseInt(obj.id),
          user_name: obj.user_name,
          email: obj.email,
          phone: obj.phone,
        }
      })
    },

    courseModeList: function () {
      return this.$courseModeList
    },

    courseTimeList: function () {
      let result = []

      if (this.courseMode === 'offline') {
        for (let i = 0; i < this.dbCourseList.length; i++) {
          if (this.$validate.DataValid(this.dbCourseList[i].lessons)) {
            if (this.dbCourseList[i].lessons.length > 0) {
              result.push({ id: this.dbCourseList[i].id, date: this.dbCourseList[i].lessons[0].date })
            }
          }
        }
      }

      return result
    },

    applyCountList: function () {
      let result = []

      if (this.courseMode === 'offline' && this.$validate.DataValid(this.courseID)) {
        const courseData = this.dbCourseList.filter(obj => {
          return obj.id === parseInt(this.courseID)
        })

        if (courseData.length > 0) {
          const maxCapacity = parseInt(courseData[0].max_student) - parseInt(courseData[0].apply_count)
          for (let i = 1; i <= maxCapacity; i++) {
            result.push(i)
          }
        }
      }

      return result
    },

    couponList: function () {
      return this.dbCouponList.map(obj => {
        return {
          text: `${obj.coupon_code} (${
            obj.type === 'percentage' ? `${(parseFloat(obj.value) / 10).toFixed(1)}折` : `減$${obj.value}`
          })`,
          value: obj.coupon_code,
        }
      })
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    // initialize
    async getUserList() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_user', payload)
        this.$func.log('-----Get User List-----')
        this.$func.log(result)

        this.dbUserList = result.data
      } catch (error) {
        this.$func.log('-----Get User List fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } finally {
        this.setFetching(false)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    filterUser(item, queryText, itemText) {
      const username = item.user_name.toLowerCase()
      const phone = item.phone
      const email = item.email.toLowerCase()

      const searchText = queryText.toLowerCase()

      return username.indexOf(searchText) !== -1 || phone.indexOf(searchText) !== -1 || email.indexOf(searchText) !== -1
    },

    // user selected
    userChange() {
      this.courseMode = ''
      this.courseDate = ''
      this.courseID = ''
      this.amount = ''
      this.couponCode = ''
      this.paymentType = 'all'
      this.filterCourseList = []
      this.selectedCourseData = {}
      this.selectedCouponData = {}
      this.dbCourseList = []
      this.dbCouponList = []

      this.calculatePrice()
      this.getCouponList()
    },

    // call after user selected
    async getCouponList() {
      if (!this.isFetching) {
        if (this.$validate.DataValid(this.userID)) {
          this.dbCouponList = []
          this.couponCode = ''
          this.selectedCouponData = {}

          try {
            const payload = {
              verify_token: this.getAdminToken(),
              user_id: parseInt(this.userID),
              status: 'active',
            }

            const result = await this.$XHR.api('cms_get_coupon_list', payload)
            this.$func.log('-----Get Coupon-----')
            this.$func.log(result)

            this.dbCouponList = result.data
          } catch (error) {
            this.$func.log('-----Get Coupon fail-----')
            this.$func.log(error)

            if (error.data === 'admin verification fail') {
              this.forceLogout()
            }
          } finally {
            this.setFetching(false)
          }
        }
      }
    },

    // call after course mode selected
    async getRelatedCourse(mode) {
      if (!this.isFetching) {
        if (this.$validate.DataValid(mode)) {
          this.courseDate = ''
          this.courseID = ''
          this.paymentType = 'all'
          this.amount = ''
          this.couponCode = ''
          this.filterCourseList = []
          this.selectedCourseData = {}
          this.dbCourseList = []

          this.setFetching(true)

          try {
            const payload = {
              verify_token: this.getAdminToken(),
              course_mode: mode,
              status: 'active',
              check_can_apply: mode === 'offline',
              get_apply_count: mode === 'offline',
              get_lesson: mode === 'offline',
              check_applied_before: true,
              user_id: parseInt(this.userID),
            }

            const result = await this.$XHR.api('cms_get_course', payload)
            this.$func.log('-----Get Course List-----')
            this.$func.log(result)

            if (mode === 'offline') {
              // check if the offline course is available to apply
              this.dbCourseList = result.data.filter(obj => {
                return obj.can_apply
              })
            } else {
              // filter out the online course that user haven't applied before
              this.dbCourseList = result.data

              this.filterCourseList = this.dbCourseList
                .filter(obj => {
                  return !obj.applied_before
                })
                .map(obj => {
                  return { text: `${obj.course_name} (${obj.course_code})`, value: obj.id }
                })
            }
          } catch (error) {
            this.$func.log('-----Get Course List fail-----')
            this.$func.log(error)

            if (error.data === 'admin verification fail') {
              this.forceLogout()
            }
          } finally {
            this.setFetching(false)
            this.calculatePrice()
          }
        }
      }
    },

    // if course mode = offline
    filterAllowCourseDate(date) {
      const dateList = this.courseTimeList.map(obj => {
        return obj.date
      })
      return dateList.includes(date)
    },

    // after select course date
    filterCourseByDate(action) {
      if (action === 'confirm') {
        this.filterCourseList = []
        this.selectedCourseData = {}
        this.amount = ''
        this.courseID = ''
        this.couponCode = ''

        const filterDate = this.courseTimeList
          .filter(obj => {
            return obj.date === this.courseDate
          })
          .map(obj => {
            return obj.id
          })

        if (filterDate.length > 0) {
          const filterCourse = this.dbCourseList
            .filter(obj => {
              return filterDate.includes(obj.id)
            })
            .map(obj => {
              return { text: `${obj.course_name} (${obj.course_code})`, value: obj.id }
            })

          this.filterCourseList = filterCourse
        }
      }
    },

    // after select course id
    updateSelectedCourseData(id) {
      this.selectedCourseData = {}
      this.amount = ''
      this.paymentType = 'all'
      this.couponCode = ''

      const search = this.dbCourseList.filter(obj => {
        return obj.id === parseInt(id)
      })

      if (search.length > 0) {
        this.selectedCourseData = search[0]
      }

      this.calculatePrice()
    },

    // after select no. of person join the offline course
    updatePersonAmount() {
      this.calculatePrice()
    },

    // after the user select a coupon
    updateCouponCode() {
      this.selectedCouponData = {}

      const search = this.dbCouponList.filter(obj => {
        return obj.coupon_code === this.couponCode
      })

      if (search.length > 0) {
        this.selectedCouponData = search[0]
      }

      this.calculatePrice()
    },

    // cancel the use of coupon
    cancelCouponCode() {
      this.couponCode = ''
      this.selectedCouponData = {}

      this.calculatePrice()
    },

    // change the paymentType
    changePaymentType() {
      this.calculatePrice()
    },

    calculatePrice() {
      this.discount = 0 //訂單折扣
      this.total = 0 // 訂單總額
      this.payAmount = 0 // 應付金額
      this.reservationFee = 0 // 留位費

      // case 1: 直接俾曬所有錢
      // case 2: 俾左留位費先遲下再俾埋剩低嘅錢

      let updatePayAmountForDiscount = false

      if (this.$validate.DataValid(this.selectedCourseData)) {
        if (this.courseMode === 'online') {
          this.total = parseFloat(this.selectedCourseData.price)
          updatePayAmountForDiscount = true
        } else if (this.courseMode === 'offline' && this.$validate.DataValid(this.amount)) {
          this.reservationFee = parseFloat(this.selectedCourseData.reservation_fee) * parseInt(this.amount)
          this.total = parseFloat(this.selectedCourseData.price) * parseInt(this.amount)
          if (this.paymentType === 'all') {
            // pay all
            updatePayAmountForDiscount = true
          } else if (this.paymentType === 'reserve') {
            // pay reserve only
            this.payAmount = this.reservationFee
          }
        }

        if (this.$validate.DataValid(this.couponCode)) {
          if (this.total !== 0) {
            if (this.selectedCouponData.type === 'percentage') {
              this.discount = (this.total * (100 - parseFloat(this.selectedCouponData.value))) / 100
              this.total = (this.total * parseFloat(this.selectedCouponData.value)) / 100
            } else if (this.selectedCouponData.type === 'fix') {
              this.discount = parseFloat(this.selectedCouponData.value)
              this.total -= this.discount
            }
          }
        }

        if (updatePayAmountForDiscount) {
          this.payAmount = this.total
        }

        this.discount = parseFloat(this.discount.toFixed(1))
        this.payAmount = parseFloat(this.payAmount.toFixed(1))
        this.total = parseFloat(this.total.toFixed(1))
        this.reservationFee = parseFloat(this.reservationFee.toFixed(1))
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)
        const validate = this.$refs['order-create-form'].validate()

        if (!validate) {
          this.setFetching(false)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            user_id: parseInt(this.userID),
            course_id: parseInt(this.courseID),
            payment_method: 'fps',
            amount: this.$validate.DataValid(this.amount) ? parseInt(this.amount) : 1,
            total: this.payAmount,
            coupon_code: this.couponCode,
            fps_record: this.uploadRecord,
            pay_reservation: this.paymentType !== 'all',
          }

          const result = await this.$XHR.api('add_order', payload)
          this.$func.log('-----Add Order-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '提交成功',
            isError: false,
            returnLink: { name: 'order' },
          })
          this.setShowDialog(true)

          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----Add Order Fail-----')
          this.$func.log(error)

          this.submitSuccess = false

          let message = '提交失敗'

          if (error.data === 'admin verification fail') {
            message = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'user not found') {
            message = '用戶不存在'
          } else if (error.data === 'course does not exist') {
            message = '課程不存在'
          } else if (error.data === 'course capacity is not enough') {
            message = '課程名額不足'
          } else if (error.data === 'applied before') {
            message = '同一用戶不可重覆報名'
          } else if (error.data === 'price not match') {
            message = '價錢不正確'
          } else if (error.data === 'payment record missing') {
            message = '沒有付款證明'
          } else if (error.data === 'invalid payment method') {
            message = '付款方法不正確'
          } else if (error.data === 'missing payment method') {
            message = '沒有付款方法'
          } else if (error.data === 'user is not permitted to use this coupon') {
            message = '用戶不可使用此優惠劵'
          } else if (error.data === 'coupon has been used by this user') {
            message = '用戶已使用此優惠劵'
          } else if (error.data === 'coupon expired') {
            message = '優惠劵已過期或未到開始日期'
          }

          if (this.$validate.DataValid(message)) {
            this.setDialogMessage({
              message: message,
              isError: false,
              returnLink: null,
            })
            this.setShowDialog(true)
          }

          this.setFetching(false)
        }
      }
    },
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (this.submitSuccess) {
      const index = removeList.indexOf(this.uploadRecord)

      if (index !== -1) {
        removeList.splice(index, 1)
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.setFetching(true)
      this.getUserList()
    }
  },
}
</script>

<style lang="scss" scoped>
.timetable-grid {
  display: grid;
  grid-template-columns: minmax(10%, 175px) auto;
  grid-row-gap: 10px;
  margin-top: 10px;

  & > div {
    font-size: 15px;
    font-weight: 500;
  }
}

.price-grid {
  & > div {
    font-size: 15px;
  }

  & > .divider {
    grid-column-start: 1;
    grid-column-end: 3;
    height: 1px;
    background-color: #e3e3e3;
  }

  .grid-value {
    text-align: right;
  }

  .emphasize {
    font-size: 20px;
    font-weight: bold;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
}

@media screen and (max-width: 599.9px) {
  .code-area {
    display: flex;
    justify-content: center;
  }
}
</style>